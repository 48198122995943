import React, { useEffect, useState } from 'react';
import {
  DeepLinkingConfig,
  DeepLinkingProvider,
} from 'assets/core/deep-linking';
import { RootNavigation } from './modules/navigation/RootNavigation';
import { NavigationContainer } from './modules/common/NavigationContainer';
import { ApiConfig } from 'assets/core/api';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageKeys } from './enums/storage-keys';
import AppConfigService from './modules/api/AppConfigService';
import { LoadingIndicator } from 'assets/components/loading-indicator';

const fetchJsFromCDN = (src: string, externals = []) => {
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.setAttribute('src', src);
    script.addEventListener('load', () => {
      resolve(
        externals.map((key) => {
          const ext = window[key];
          typeof ext === 'undefined' &&
            console.warn(`No external named '${key}' in window`);
          return ext;
        }),
      );
    });
    script.addEventListener('error', reject);
    document.body.appendChild(script);
  });
};

const fetchCssFromCDN = (src: string) => {
  new Promise((resolve, reject) => {
    const script = document.createElement('link');
    script.setAttribute('rel', 'stylesheet');
    script.setAttribute('href', src);
    script.addEventListener('error', reject);
    document.body.appendChild(script);
  });
};

fetchJsFromCDN(
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.9.179/pdf.min.js',
);

fetchCssFromCDN(
  'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.9.179/web/pdf_viewer.min.css',
);

ApiConfig.setBaseApiConfig({
  getAccessToken: async () => {
    // setting token key as convention in order to get the key of the token since proxy app is different from mobile
    const tokenKey = await AsyncStorage.getItem('TokenKey');
    return await AsyncStorage.getItem(tokenKey ?? StorageKeys.AccessToken);
  },
});

export default function App() {
  const [deepLinkingConfig, setDeepLinkingConfig] =
    useState<DeepLinkingConfig>();

  useEffect(() => {
    (async () => {
      const dlc = await AppConfigService.getDeepLinkingConfig();
      setDeepLinkingConfig(dlc);
    })();
  }, []);

  return deepLinkingConfig ? (
    <NavigationContainer>
      <DeepLinkingProvider
        handleAutomaticRedirection={false}
        deepLinkingConfig={deepLinkingConfig}
      >
        <RootNavigation />
      </DeepLinkingProvider>
    </NavigationContainer>
  ) : (
    <LoadingIndicator />
  );
}
