import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { FORMS_API_BASE_URL } from '../common/constants';
import {
  FormAccessData,
  FormsApi as FormsServiceClient,
} from '@digitalpharmacist/forms-service-client-axios';
export interface IFormsService {}

export class FormsService extends BaseApiService implements IFormsService {
  private formsServiceClient: FormsServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.formsServiceClient = new FormsServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async checkFormAccess(formId: string): Promise<FormAccessData> {
    const { data } = await this.formsServiceClient.formsFormAccess(formId);

    return data;
  }
}

export default new FormsService(FORMS_API_BASE_URL, undefined, true);
