import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';

export const GViewBaseUrl: string = `https://docs.google.com/gview?embedded=true&url=`;
export const ViewerNGBaseUrl: string = `https://docs.google.com/viewerng/viewer?embedded=true&url=`;

export interface IDocumentService {
  getBlobParts(src: string): Promise<BlobPart>;
}

export class DocumentService
  extends BaseApiService
  implements IDocumentService
{
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
  }

  async getBlobParts(src: string, auth?: boolean): Promise<BlobPart> {
    const { data } = await this.axiosInstance.get(src, {
      responseType: 'blob', //Force to receive data in a Blob Format
      disableAuth: !auth,
    });
    return data as BlobPart;
  }
}

export default new DocumentService('');
