import React, { FunctionComponent, useEffect, useState } from 'react';
import { IconButton } from 'assets/components/icon-button';
import { MinusCircleIcon, PlusCircleIcon } from 'assets/icons';
import { convertDataURIToBinary } from './utils';

export const PDFViewer: FunctionComponent<{
  src?: string;
}> = ({ src = '' }) => {
  const [pdf, setPdf] = useState<any>();
  const [scale, setScale] = useState<number>(1);

  function renderPage(num: number) {
    pdf.getPage(num).then(function (page: any) {
      const canvasContainer = document.getElementById('pdf-viewer');
      canvasContainer?.replaceChildren();

      const canvas = document.createElement('canvas');

      canvas.setAttribute('id', `${num}`);

      const viewport = page.getViewport({ scale: scale });

      const canvasContext = canvas?.getContext('2d');

      var outputScale = window.devicePixelRatio || 1;

      canvas.width = Math.floor(viewport.width * outputScale);
      canvas.height = Math.floor(viewport.height * outputScale);
      canvas.style.width = Math.floor(viewport.width) + 'px';
      canvas.style.height = Math.floor(viewport.height) + 'px';

      var transform =
        outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

      const renderContext = { canvasContext, viewport, transform };
      page.render(renderContext);

      canvasContainer?.appendChild(canvas);
    });
  }

  function renderAllPages() {
    for (var i = 1; i <= pdf.numPages; i++) {
      renderPage(i);
    }
  }

  useEffect(() => {
    (async function () {
      // We import this here so that it's only loaded during client-side rendering.
      const pdfJS: any = window['pdfjs-dist/build/pdf' as any];
      pdfJS.GlobalWorkerOptions.workerSrc =
        '//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.9.179/pdf.worker.min.js';
      const pdfResponse = await pdfJS.getDocument(convertDataURIToBinary(src))
        .promise;

      setPdf(pdfResponse);
    })();
  }, []);

  useEffect(() => {
    if (!pdf) return;

    // Render PDF page into canvas context.

    renderAllPages();
    // page.render(renderContext);
  }, [pdf, scale]);

  const handleIncrement = () => {
    setScale((value) => (value < 1.75 ? value + 0.25 : value));
  };

  const handleDecrement = () => {
    setScale((value) => (value > 0.5 ? value - 0.25 : value));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexGrow: 1,
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          overflowY: 'scroll',
        }}
        id="pdf-viewer"
      />
      <div
        style={{
          position: 'fixed',
          display: 'flex',
          bottom: 5,
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <IconButton
          icon={MinusCircleIcon}
          logger={{ id: 'minus' }}
          onPress={handleDecrement}
          disabled={scale <= 0.5}
        />
        <IconButton
          icon={PlusCircleIcon}
          logger={{ id: 'plus' }}
          onPress={handleIncrement}
          disabled={scale >= 1.75}
        />
      </div>
    </div>
  );
};
