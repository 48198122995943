import React from 'react';
import { DeepLinkingConfig } from '../types';

export type DeepLinkingContextType = {
  deepLinkingConfig: DeepLinkingConfig;
  setDeepLinkingConfig: (override: Partial<DeepLinkingConfig>) => void;
  launchApp: (redirectUrl: string, timeout?: number) => void;
  currentUrl: string;
};

export const DeepLinkingContext = React.createContext<DeepLinkingContextType>({
  deepLinkingConfig: {} as DeepLinkingConfig,
  setDeepLinkingConfig: () => {},
  launchApp: () => {},
  currentUrl: '',
});

export const DeepLinkingConsumer = DeepLinkingContext.Consumer;
