import React, { FunctionComponent, PropsWithChildren } from 'react';
import { NavigationContainer as ReactNavigationNavigationContainer } from '@react-navigation/native';
import * as Linking from 'expo-linking';

/*
 * Wrapper for React Navigation's Navigation Container with extended functionality for tracking screen changes
 * Reference: https://reactnavigation.org/docs/screen-tracking/#example
 */
export const NavigationContainer: FunctionComponent<
  PropsWithChildren<NavigationContainerProps>
> = ({ children }) => {
  const prefix = Linking.createURL('/');
  const linking = {
    prefixes: [prefix],
  };

  return (
    <ReactNavigationNavigationContainer linking={linking}>
      {children}
    </ReactNavigationNavigationContainer>
  );
};

interface NavigationContainerProps {}
