import Constants from 'expo-constants';

export const ENVIRONMENT = Constants.manifest?.extra?.ENVIRONMENT;

export const FORMS_API_BASE_URL = Constants.manifest?.extra?.FORMS_API_BASE_URL;

export const APP_CONFIG_API_BASE_URL =
  Constants.manifest?.extra?.APP_CONFIG_API_BASE_URL;

export const SCHEME = Constants.manifest?.extra?.SCHEME;

export const DOMAIN = Constants.manifest?.extra?.DOMAIN;

export const APP_ENV = Constants.manifest?.extra?.APP_ENV;
