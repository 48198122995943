import React, { useEffect, useState } from 'react';
import { View, Text, Button } from 'react-native';
import { useDeepLinkingContext } from 'assets/core/deep-linking';
import { useTheme } from 'assets/theme';
import * as Linking from 'expo-linking';

export const Root = () => {
  const [redirectionFiled, setRedirectionFailed] = useState(false);
  const theme = useTheme();
  const { currentUrl, launchApp } = useDeepLinkingContext();

  useEffect(() => {
    setTimeout(() => setRedirectionFailed(true), 2000);
  }, []);

  const handleRedirection = () => {
    if (currentUrl && currentUrl !== '') {
      const { path, queryParams } = Linking.parse(currentUrl);
      const url = [
        path ?? '/',
        queryParams &&
          new URLSearchParams(queryParams as Record<string, string>).toString(),
      ]
        .filter((x) => x)
        .join('?');

      console.log(url);
      launchApp(url ?? '/');
    }
  };

  useEffect(handleRedirection, [currentUrl]);

  return (
    <View>
      <Text>Redirecting to: {currentUrl}</Text>

      {!redirectionFiled && (
        <Text>Please wait. Your page is being redirected...</Text>
      )}
      {redirectionFiled && (
        <>
          <Text>
            Redirection failed. Please try again by clicking on the button or go
            back and try again.
          </Text>
          <View style={{ maxWidth: 250, padding: theme.getSpacing(2) }}>
            <Button title="Click" onPress={handleRedirection} />
          </View>
        </>
      )}
    </View>
  );
};
