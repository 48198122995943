import { DefaultDeepLinkingConfig } from '../config';
import { Platform } from 'react-native';

export const isChrome = () => navigator.userAgent.match(/Chrome/);
export const isFirefox = () => navigator.userAgent.match(/Firefox/);
export const isAndroidBrowser = () => /Android/i.test(navigator.userAgent);
export const isIOSBrowser = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);
export const isDocumentHidden = () => document.webkitHidden || document.hidden;
export const isIOS = () => Platform.OS === 'ios';
export const isAndroid = () => Platform.OS === 'android';
export const isWeb = () => Platform.OS === 'web';
export const canHandleIntent = () => isChrome() || isFirefox();

export const tryIframeApproach = (
  launchAppUrl: string,
  altUrl: string,
  timeout: number,
) => {
  var iframe = document.createElement('iframe');
  iframe.id = 'iframe_app';
  iframe.style.border = 'none';
  iframe.style.width = '1px';
  iframe.style.height = '1px';
  iframe.onload = function () {
    document.location.href = altUrl;
  };
  iframe.src = launchAppUrl;
  document.body.appendChild(iframe);
  setTimeout(() => {
    document.body.removeChild(document.getElementById('iframe_app')!);
  }, timeout + 1000);
};

/**
 * This function will append a meta tag on the header
 * that will tell to safari how to generate the smart banner content
 * https://developer.apple.com/documentation/webkit/promoting_apps_with_smart_app_banners
 */
const appendMetaTagForSmartBanner = (
  // TODO: remove default values, they are referencing to a random pocket rx application
  appId: string = DefaultDeepLinkingConfig.ios.appId,
  appArgument?: string,
) => {
  var meta = document.createElement('meta');
  meta.setAttribute('name', 'apple-itunes-app');
  meta.setAttribute('content', `app-id=${appId}, app-argument=${appArgument}`);
  document.getElementsByTagName('head')[0].prepend(meta);
};

/**
 * In order this to work on chrome there are some criteria to match
 * https://developer.chrome.com/blog/app-install-banners-native/#criteria
 */
const listenToInstallPrompt = () => {
  let deferredPrompt;

  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    const response = confirm(
      'There is a native version of this app. Do you want to install it?',
    );
    // @ts-ignore
    if (response) deferredPrompt.prompt();
  });
};

/**
 * Will inject meta tag for safari and will listen to `beforeinstallprompt` for chrome
 */
export const initSmartBanner = () => {
  isIOSBrowser() && listenToInstallPrompt();

  isAndroidBrowser() && appendMetaTagForSmartBanner();
};
