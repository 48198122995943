import React, { FunctionComponent, useEffect, useState } from 'react';
import { View, Text, Button } from 'react-native';
import { useDeepLinkingContext } from 'assets/core/deep-linking';
import { useTheme } from 'assets/theme';
import * as Linking from 'expo-linking';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import type { StackScreenProps } from '@react-navigation/stack';
import { RootNavigationParamList } from '../../navigation/RootNavigation';

interface BrightcovePlayerScreenProps
  extends StackScreenProps<RootNavigationParamList, 'brightcove-player'> {}

export const BrightcovePlayerScreen: FunctionComponent<
  BrightcovePlayerScreenProps
> = ({ route }) => {
  const { video_id, account_id, player_id } = route.params;

  return (
    <View
      style={{
        flex: 1,
      }}
    >
      <ReactPlayerLoader
        accountId={account_id}
        videoId={video_id}
        playerId={player_id}
        // onSuccess={onSuccess}
        // onFailure={onFailure}
        embedOptions={{ responsive: true }}
        manualReloadFromPropChanges="true"
        options={{
          autoplay: false,
          controls: false,
          preload: 'metadata',
          fluid: true,
          playsinline: true,
        }}
      />
    </View>
  );
};
