import React, { useEffect, useState } from 'react';
import { useDeepLinkingContext } from 'assets/core/deep-linking';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import DocumentService, { GViewBaseUrl } from '../../api/DocumentService';
import * as Linking from 'expo-linking';
import { Alert } from 'assets/components/alert';
import { blobToBase64 } from '../../components/pdf-viewer/utils';
import { PDFViewer } from '../../components/pdf-viewer';

export const DocumentsScreen = () => {
  const { currentUrl } = useDeepLinkingContext();

  // can can be a url like
  // https://somedomain.com/dummyfile.pdf
  // blob://local-domain/uid-filename
  // base64 string
  // based on conditions like, static file or streaming file, android or other platform
  const [source, setSource] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [params, setParams] = useState<
    | {
        src: string;
        streaming: boolean;
        auth: boolean;
        platform: string;
        isAndroid: boolean;
      }
    | undefined
  >();

  const handleStaticFile = (src: string) => {
    if (params?.isAndroid) window.location.href = GViewBaseUrl + src;
    else setSource(GViewBaseUrl + src); // web and ios will render the iframe
  };

  const handleBlobData = async (blobPart: BlobPart) => {
    const fileInMemory = new Blob([blobPart], { type: 'application/pdf' });
    const src = params?.isAndroid
      ? await blobToBase64(fileInMemory)
      : URL.createObjectURL(fileInMemory);
    setSource(src);
  };

  const handleStreamingDocument = async (streamSource: string) => {
    try {
      const blobData: BlobPart = await DocumentService.getBlobParts(
        streamSource,
        params?.auth,
      );
      handleBlobData(blobData);
    } catch (error: any) {
      setError(JSON.stringify(error));
    }
  };

  useEffect(() => {
    // static file
    if (params?.src && params.src !== source && !params.streaming)
      handleStaticFile(params.src);

    // in case we have a streaming link
    if (params?.src && params.src !== source && params.streaming)
      handleStreamingDocument(params.src);
  }, [params]);

  useEffect(() => {
    if (!currentUrl) return;
    const { queryParams } = Linking.parse(currentUrl);
    if (!queryParams?.url) return;

    const src = decodeURIComponent(queryParams?.url as string);
    const auth = Boolean(queryParams?.auth === 'true');
    const streaming = Boolean(queryParams?.streaming === 'true');
    const platform = queryParams?.platform as string;
    const isAndroid = platform === 'android';

    setParams({ src, auth, streaming, platform, isAndroid });
  }, [currentUrl]);

  const render = () => {
    if (error)
      return (
        <Alert intent="error" title="Error" description={error}>
          {error}
        </Alert>
      );

    if (!source) return <LoadingIndicator />;

    if (params?.streaming && params?.isAndroid)
      return <PDFViewer src={source} />;

    return <iframe src={source} width={'100%'} height={'100%'} />;
  };

  return render();
};
