import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { RouteProp, useRoute } from '@react-navigation/native';
import { FormAccessType } from '@digitalpharmacist/forms-service-client-axios';
import * as Linking from 'expo-linking';

import { useTheme } from 'assets/theme';
import {
  isAndroidBrowser,
  isIOSBrowser,
  useDeepLinkingContext,
} from 'assets/core/deep-linking';
import { Alert } from 'assets/components/alert';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import FormsService from '../../api/FormsService';
import { RootNavigationParamList } from '../../navigation/RootNavigation';

export const FormsScreen = () => {
  const theme = useTheme();
  const route = useRoute<RouteProp<RootNavigationParamList, 'forms'>>();

  const [redirectionFiled, setRedirectionFailed] = useState<boolean>(false);
  const [renderPublicForm, setRenderPublicForm] = useState<boolean>(false);
  const [publicFormHtml, setPublicFormHtml] = useState<string | null>(null);

  const { currentUrl, launchApp } = useDeepLinkingContext();

  const handleRedirection = async () => {
    const formId = route.params.form_id;

    // If no route param, we can't redirect properly
    if (!formId) {
      setRedirectionFailed(true);
      return;
    }

    try {
      // Check if the form is public or private
      const formAccessData = await FormsService.checkFormAccess(formId);
      if (
        formAccessData &&
        formAccessData.formAccessType === FormAccessType.Private
      ) {
        if (isAndroidBrowser() || isIOSBrowser()) {
          launchApp(
            `app/forms?form_id=${formId}&location_id=${formAccessData.locationId}`,
          );
        } else if (formAccessData.formRedirectUrl) {
          Linking.openURL(formAccessData.formRedirectUrl);
        } else {
          // Missing data on back-end side -> redirection failed
          setRedirectionFailed(true);
        }
      } else if (formAccessData.formContent) {
        // if the form is public we render it inside the page
        setRenderPublicForm(true);
        setPublicFormHtml(formAccessData.formContent);
      } else {
        // Missing data on back-end side -> redirection failed
        setRedirectionFailed(true);
      }
    } catch {
      setRedirectionFailed(true);
    }
  };

  useEffect(() => {
    handleRedirection();
  }, [currentUrl]);

  return (
    <View
      style={{
        width: '100%',
        height: '100%',
        flex: 1,
      }}
    >
      {renderPublicForm && publicFormHtml && (
        <iframe
          style={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.gray[500],
            borderRadius: 8,
            outline: 'none',
            boxSizing: 'border-box',
            boxShadow: 'none',
          }}
          height="100%"
          width="100%"
          srcDoc={publicFormHtml}
        ></iframe>
      )}
      {!renderPublicForm && (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          {redirectionFiled && (
            <Alert
              intent="error"
              title="An error occurred while trying to open the form, please try again later."
            />
          )}

          {!redirectionFiled && <LoadingIndicator />}
        </View>
      )}
    </View>
  );
};
