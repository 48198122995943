import React, { PropsWithChildren, useEffect } from 'react';
import {
  NavigationProp,
  ParamListBase,
  useNavigation,
} from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { DefaultDeepLinkingConfig } from '../config';
import { DeepLinkingContext } from '../context/deep-linking-context';
import { DeepLinkingConfig, DeepLinkingProviderProp } from '../types';
import { useCurrentUrl } from '../hooks';
import {
  canHandleIntent,
  isAndroidBrowser,
  isDocumentHidden,
  isIOSBrowser,
  isWeb,
} from '../utils';

export const DeepLinkingProvider: React.FunctionComponent<
  PropsWithChildren<DeepLinkingProviderProp>
> = ({
  children,
  deepLinkingConfig,
  navigation: navigationProp,
  isAuthorized,
  returnUrlKey = 'returnUrl',
  handleAutomaticRedirection = true,
}) => {
  const { url: currentUrl, setUrl: setCurrentUrl } = useCurrentUrl();
  const navigation =
    navigationProp ?? useNavigation<NavigationProp<ParamListBase>>();

  const [currentDeepLinking, setCurrentDeepLinking] =
    React.useState<DeepLinkingConfig>(
      deepLinkingConfig ?? DefaultDeepLinkingConfig,
    );

  const setDeepLinkingConfig = (override: Partial<DeepLinkingConfig>) => {
    setCurrentDeepLinking((curr) => ({
      ...curr,
      ...override,
    }));
  };

  const launchAppOrAltUrl = (redirectUrl: string, timeout: number = 2500) => {
    let timer: NodeJS.Timeout;
    let heartbeat: NodeJS.Timer;

    const launchAppUrl = `${currentDeepLinking.scheme}://${redirectUrl}`;
    const altUrl = isAndroidBrowser()
      ? currentDeepLinking.android.fallbackUrl
      : isIOSBrowser()
      ? currentDeepLinking.ios.fallbackUrl
      : '';

    function clearTimers() {
      clearTimeout(timer);
      clearTimeout(heartbeat);
    }

    function intervalHeartbeat() {
      if (isDocumentHidden()) {
        clearTimers();
      }
    }

    function tryUniversalLinkApproach() {
      document.location.href = launchAppUrl;
      // send alert to user if want ro proceed to alternative url
      timer = setTimeout(() => {
        const response = confirm(
          'It seems that you have not installed our app. Do you want to go to the store and install it?',
        );
        if (response) document.location.href = altUrl;
      }, timeout);
    }

    function tryIntentApproach() {
      const googleChromeIntent = `intent://${redirectUrl}#Intent;scheme=${currentDeepLinking.scheme};package=${currentDeepLinking.android.package};S.browser_fallback_url=${currentDeepLinking.android.fallbackUrl};end`;
      document.location.href = googleChromeIntent;
    }

    heartbeat = setInterval(intervalHeartbeat, timeout / 10);

    if (canHandleIntent() && !isIOSBrowser()) {
      tryIntentApproach();
    } else {
      tryUniversalLinkApproach();
    }
  };

  const navigateTo = (path: string, queryParams: Record<string, string>) => {
    // considering that each route will be separated by '/'
    // we should never use that character as the name of a screen as
    // <Stack.Screen name="profile/settings" component={} /> as this will be treated as 2 routes
    // instead of the slash we should use dash '-' (as it is implemented till now)
    const routes = path?.split('/');
    const rootPath = routes.shift() ?? '/';

    const params =
      routes.length > 0
        ? routes.reduceRight(
            // create nested params recursively
            (acc, curr, index) => ({
              screen: curr,
              params: index === routes.length - 1 ? queryParams : acc,
            }),
            {},
          )
        : // otherwise returns actual query parameters
          queryParams;

    navigation.navigate(rootPath, params);
  };

  const handleWebAuthorizationChange = async () => {
    const { path, queryParams } = Linking.parse(currentUrl);
    if (!path) return;

    if (!isAuthorized) {
      const params: any = {
        // take current return key otherwise take url as return key
        [returnUrlKey]:
          queryParams?.[returnUrlKey] ??
          [
            path,
            queryParams &&
              new URLSearchParams(
                queryParams as Record<string, string>,
              ).toString(),
          ]
            .filter((x) => x)
            .join('?'),
      };
      navigation.setParams(params);
    } else {
      // if the url contains the return key on query params then redirect to that url
      if (queryParams?.[returnUrlKey]) {
        const [returnUrlPath, returnUrlQueryParams] = (
          queryParams?.[returnUrlKey] as string
        )?.split('?');

        // workaround
        // expo / react navigation need to register first the new routes and then we should redirect
        setTimeout(() => {
          navigateTo(
            returnUrlPath,
            Object.fromEntries(new URLSearchParams(returnUrlQueryParams)),
          );
        }, 10);
      }
    }
  };

  const handleUniversalLink = async () => {
    // in case of universal links, we dont have return url, the current url is changing only once when the user clicks on a link
    // after that it is changing only the navigation state
    const { path, queryParams } = Linking.parse(currentUrl);
    if (!path) return;

    navigateTo(path, queryParams as Record<string, string>);
  };

  // subscribe to url and authorization changes for mobile only
  useEffect(() => {
    if (!handleAutomaticRedirection) return;
    if (isWeb()) return; // mobile only
    if (!currentUrl) return;
    handleUniversalLink();
    if (isAuthorized) setCurrentUrl(''); // in order to clean the state after authorized redirect
  }, [currentUrl, isAuthorized, handleAutomaticRedirection]);

  // subscribe to authorization state changes for web only and handle redirect url
  useEffect(() => {
    if (!handleAutomaticRedirection) return;
    if (!isWeb()) return; // web only
    if (!currentUrl) return;

    handleWebAuthorizationChange();
  }, [isAuthorized, handleAutomaticRedirection]);

  return (
    <DeepLinkingContext.Provider
      value={{
        deepLinkingConfig: currentDeepLinking,
        setDeepLinkingConfig: setDeepLinkingConfig,
        launchApp: launchAppOrAltUrl,
        currentUrl,
      }}
    >
      {children}
    </DeepLinkingContext.Provider>
  );
};
